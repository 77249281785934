<template>
  <image-form-component
    type="add"
    @submit="save"
    @close="close"
  />
</template>
<script lang="ts" setup>
import { useRoute, useRouter } from "vue-router";
import { imageService } from "../bootstrap";
import ImageFormComponent, { image } from "./image-form.component.vue";

const $router = useRouter();
const $route = useRoute();

async function save(form: image): Promise<void> {
  try {
    await imageService.create({
      name: form.name,
      tags: form.tags
    }, form.image);

    close();
  } catch {
    alert("Something went wrong");
  }
}

function close(): void {
  $router.push({ path: "/image", query: { ...$route.query } });
}
</script>
<style lang="scss" scoped>

</style>