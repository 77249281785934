// Libs
import { I18n, createI18n } from "vue-i18n";

// Locale
import { BroadcastService, ISubscription } from "../../../shared/services/broadcast.service";
import en from "../../resources/locales/en.json";
import sv from "../../resources/locales/sv.json";


// Shared Services

// Services
import { BrowserService } from "./browser.service";
import { StorageService } from "./storage.service";

export type language = { key: string, name: string, localName: string };

export type weekdays = { short: string[], long: string[] };

export class LocaleService {
  public constructor(
    private readonly defaultLanguage: string,
    public readonly languages: Record<string, string>,
    private readonly broadcastService: BroadcastService,
    private readonly storageService: StorageService,
    private readonly browserService: BrowserService
  ) { }
  
  private _vueI18n?: I18n<unknown, unknown, unknown, "sv", false>;
  public get vueI18n(): I18n<unknown, unknown, unknown, "sv", false> {
    if (this._vueI18n === undefined) { throw new Error("VueI18n has not yet been initiated"); }
    return this._vueI18n;
  }

  public get currentLanguageByKey(): string {
    return this.vueI18n.global.locale.value;
  }

  public get currentLanguage(): language {
    return this.getLanguageByKey(this.currentLanguageByKey);
  }

  public get allLanguages(): language[] {
    return Object.keys(this.languages).map((key: string) => ({
      key,
      name: this.languages[key],
      localName: this.vueI18n.global.t(`language.${this.languages[key]}`, key).toString(),
    }));
  }


  public init(): I18n<unknown, unknown, unknown, "sv", false> {
    if(this._vueI18n !== undefined) {
      return this.vueI18n;
    }
    this._vueI18n = createI18n({
      legacy: false,
      locale: this.defaultLanguage,
      messages: {
        en,
        sv
      },
    });

    // Check if language preference is stored in storage
    const storedLanguage: string | undefined = this.storageService.get<string>("locale");
    // Find first matching language from browser
    const browserLang: string | undefined = Object.keys(this.languages).find((key: string) => this.browserService.language.match(new RegExp(`${key}`)) !== null);
    if (storedLanguage !== undefined) {
      // Set language from storage
      this.changeLanguage(storedLanguage, true);
    } else if (browserLang !== undefined) {
      // Set language from browser
      this.changeLanguage(browserLang, true);
    } else {
      // Trigger change language just to make sure any listeners get it
      this.changeLanguage(this.currentLanguage.key, true);
    }

    /* The reason we need to emit language change on init is because
    other services that might make use of this service will not be able
    to fetch current language right away. this.init must be called first.
    For example in the API service default header for language cant be
    set in the constructor if the this.init hasn't been called yet. However
    a listner for language change can be registered immediately */
    return this.vueI18n;
  }

  public changeLanguage(to: string, alwaysEmit: boolean = false): void {
    if (Object.keys(this.languages).includes(to)) {
      if (to !== this.currentLanguageByKey) {
        // Store old key
        const oldKey: string = this.currentLanguage.key;
        // Update locale
        this.vueI18n.global.locale.value = to;

        // Emit locale change
        this.broadcastService.emit("locale.change", {
          to: this.getLanguageByKey(to),
          from: this.getLanguageByKey(oldKey)
        });

        // Save language to storage
        this.storageService.set("locale", to);
      } else if (alwaysEmit === true) {
        this.broadcastService.emit("locale.change", { to: this.getLanguageByKey(to) });
      }
    } else {
      throw new Error(`Failed to switch to language "${to}". "${to}" is not supported`);
    }
  }

  public onChange(callback: (to: language, from?: language) => void): ISubscription {
    return this.broadcastService.subscribe("locale.change", (payload?: { to: language, from?: language }) => {
      if (payload !== undefined) {
        callback(payload.to, payload.from);
      }
    });
  }

  private getLanguageByKey(key: string): language {
    return {
      key,
      name: this.languages[key],
      localName: this.vueI18n.global.t(`language.${this.languages[key]}`, key).toString(),
    };
  }

  public weekdays(): weekdays {
    if (this.currentLanguage.key === "sv") {
      return {
        short: ["Sön", "Mån", "Tis", "Ons", "Tor", "Fre", "Lör"],
        long: ["Söndag", "Måndag", "Tisdag", "Onsdag", "Torsdag", "Fredag", "Lördag"],
      };
    }
    return {
      short: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      long: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    };
  }
}