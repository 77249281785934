// Libs
import Vue from "vue";
import { NavigationGuard, NavigationGuardNext, RouteLocationRaw, RouteLocation, RouteLocationNormalized, } from "vue-router";
import { AuthService } from "./services/auth.service";

export const auth = (authService: AuthService): NavigationGuard => (to: RouteLocation, from: RouteLocation, next: NavigationGuardNext): void => {
  if(authService.isAuth === true) {
    next();
    return;
  }
  next({ name: "login", query: { redirectTo: to.fullPath } });
};
export const guest = (authService: AuthService): NavigationGuard => (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): void => {
  if(authService.isAuth === false) {
    next();
    return;  
  }

  next({ path: "/error/404" });
};

export const logout = (authService: AuthService): NavigationGuard => (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): void => {
  authService.logout();
  next({ name: "image" });
};

// Middleware for admin users
export const errorCode: NavigationGuard = (to, from, next) => {
  const errorCode = parseInt(to.params.code.toString(), 10);
  if(errorCode < 400 || errorCode > 499) {
    next({ path: "/error/404" });
    return;
  }
  next();
};

// Redirect to 404 page if route is not defined
export const invalidRoute = (redirectTo?: RouteLocationRaw): NavigationGuard => (to: RouteLocation, from: RouteLocation, next: NavigationGuardNext): void => {
  if (to.name) {
    next();
  } else {
    next(redirectTo !== undefined ? redirectTo : { path: "error/404" });
  }
};