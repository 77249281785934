// DTO's
import { IAuthLoginPayloadDTO } from "../../../shared/dto/auth-login-payload.dto";
import { ILoginDTO } from "../../../shared/dto/login.dto";
import { IUserPayloadDTO } from "../../../shared/dto/user-payload.dto";

// Services
import { APIService } from "../../../shared/services/api.service";
import { BroadcastService, ISubscription } from "../../../shared/services/broadcast.service";
import { StorageService } from "./storage.service";

export class AuthService {
  public constructor(
    private readonly apiService: APIService,
    private readonly storageService: StorageService,
    private readonly broadcastService: BroadcastService,
  ) {
    const token: string | undefined = this.storageService.get<string>("token");
    if(token !== undefined) {
      this.apiService.setDefaultHeaders({ "Authorization": `Bearer ${token}` });
    }
    this.apiService.onResponseError(response => {
      if(this.isAuth && response.status === 403) {
        alert("You have automatically logged out");
        this.logout();
      }
    });
  }

  public get isAuth(): boolean {
    return this.apiService.hasHeader("Authorization");
  }
  public get user(): IUserPayloadDTO | undefined {
    return this.storageService.get<IUserPayloadDTO>("user");
  }

  public async login(email: string, password: string): Promise<void> {
    const authResponse: IAuthLoginPayloadDTO = (await (this.apiService.post<ILoginDTO, IAuthLoginPayloadDTO>("/auth/login", { email, password }))).body;
    
    // Set token and user
    this.storageService.set("token", authResponse.token);
    this.storageService.set("user", authResponse.user);

    // Set axios header to use token
    this.apiService.setDefaultHeaders({ "Authorization": `Bearer ${authResponse.token}` });

    // Emit event
    this.broadcastService.emit("auth.login");
  }
  public logout(): void {
    this.storageService.delete("token");
    this.storageService.delete("user");
    this.apiService.removeDefaultHeaders("Authorization");
    this.broadcastService.emit("auth.logout");
  }

  public onLogin(callback: () => void): ISubscription {
    return this.broadcastService.subscribe("auth.login", callback);
  }
  public onLogout(callback: () => void): ISubscription {
    return this.broadcastService.subscribe("auth.logout", callback);
  }
  public onLoginOrLogout(callback: (isAuth: boolean) => void): ISubscription {
    const onLogin = this.onLogin(() => callback(this.isAuth));
    const onLogout = this.onLogout(() => callback(this.isAuth));

    return {
      unsubscribe: (): void => {
        onLogin.unsubscribe();
        onLogout.unsubscribe();
      }
    };
  } 
}