<template>
  <modal-component
    footer="save_abort"
    :hide-on-esc="true"
    :valid="isValid"
    @close="close"
    @submit="submit"
  >
    <template #header>
      {{ $t(`action.${type}`, { entity: $t('image.image' ).toLowerCase() }) }}
    </template>
    <div
      v-if="previewImage"
      class="image-form__preview-container"
    >
      <image-component
        :image="{
          name: form.name,
          path: previewImage
        }"
        :show-delete-button="true"
        @delete="form.image = undefined; useInitImage = false; previewImage = null"
      />
    </div>
    <file-input-component
      v-else
      v-model="form.image"
      :label="$t('image.image')"
      :init-value="useInitImage ? initWith?.image : undefined"
    />
    <input-component
      v-model="form.name"
      :label="$t('image.name')"
    />
    <tag-component
      v-model="form.tags"
      :label="$t('image.tags')"
      :allow-add="true"
    />
  </modal-component>
</template>
<script lang="ts" setup>
import { computed, onMounted, reactive, ref, watch } from "vue";
import FileInputComponent from "./file-input.component.vue";
import ImageComponent from "./image.component.vue";
import InputComponent from "./input.component.vue";
import ModalComponent from "./modal.component.vue";
import TagComponent, { options } from "./tag.component.vue";

export type image = {
  name: string;
  tags: string[];
  image?: File;
};
export type imageInitWith = Omit<image, "image"> & { image: string, imageUrl: string };

const $props = defineProps<{
  type: "add" | "edit";
  initWith?: imageInitWith;
}>();
const $emit = defineEmits(["close", "submit"]);

const isValid = computed<boolean>(() => {
  return form.name.length > 0 && (form.image !== undefined || useInitImage.value);
});

const form = reactive<{
  name: string;
  tags: options;
  image: FileList | undefined;
}>({
  name: "",
  tags: [],
  image: undefined
});
const useInitImage = ref<boolean>(false);
const previewImage = ref<string | null>(null);

watch(() => form.image, () => {
  useInitImage.value = false;
  if(form.image) {
    previewImage.value = window.URL.createObjectURL(form.image[0]);
  } else {
    previewImage.value = null;
  }
});

onMounted(() => {
  if($props.initWith) {
    useInitImage.value = true;
    previewImage.value = $props.initWith.imageUrl;
    form.name = $props.initWith.name;
    form.tags = $props.initWith.tags.map((tag) => {
      return {
        value: tag,
        text: tag
      };
    });
  }
});

function close(): void {
  $emit("close");
}

function submit(): void {
  if(!form.image && !useInitImage.value) {
    return;
  }
  $emit("submit", {
    name: form.name,
    tags: form.tags.map((tag) => tag.value),
    ...(form.image ? { image: form.image[0] } : undefined)
  } as image);
}
</script>
<style lang="scss" scoped>
  .image-form {
    &__preview-container {
      display: flex;
      justify-content: center;
    }
  }
</style>