<template>
  <div class="image-tag-list">
    <div
      class="image-tag-list__label"
      @click="isOpen = !isOpen"
    >
      {{ $t('image.tags') }}
      <i class="image-tag-list__label__caret fa-solid fa-caret-down" />
    </div>
    <div :class="['image-tag-list__container', { 'image-tag-list__container--open': isOpen }]">
      <checkbox-component
        class="image-tag-list__all"
        :label="$t('all')"
        :model-value="isAllChecked"
        @change="checkAll"
      />
      <checkbox-component
        v-for="(tag, index) of tagList"
        :key="`tag_${index}`"
        v-model="tag.checked"
        class="image-tag-list__tag"
        :label="tag.name"
        @change="emitChange"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, onMounted, reactive, ref, watch } from "vue";
import CheckboxComponent from "./checkbox.component.vue";

const $props = defineProps<{
  tags: string[];
  modelValue: string[],
}>();
const $emit = defineEmits(["update:modelValue"]);

const tagList = reactive<{ name: string, checked: boolean}[]>([]);
const isOpen = ref<boolean>(false);

const isAllChecked = computed<boolean>(() => {
  return tagList.every(tag => tag.checked);
});

watch(() => $props.tags, () => {
  updateTaglist();
});

onMounted(() => {
  updateTaglist();
  if($props.modelValue.length > 0) {
    emitChange();
  }
});

function updateTaglist(): void {
  tagList.splice(0, tagList.length);
  $props.tags.forEach(tag => {
    tagList.push({
      name: tag,
      checked: $props.modelValue.includes(tag)
    });
  });
}

function checkAll(): void {
  const isAllChecked = tagList.every(tag => tag.checked);
  tagList.forEach(tag => {
    tag.checked = !isAllChecked;
  });
  emitChange();
}

function emitChange(): void {
  $emit("update:modelValue", tagList.filter(tag => tag.checked).map(tag => tag.name));
}

</script>
<style lang="scss" scoped>
  @import "../../scss/base";

  .image-tag-list {
    @include media-breakpoint-down(md) {
      height: 30px;
      position: relative;
      z-index: 1;
    }
    &__label {
      padding: 0px 10px;
      height: 22px;
      display: flex;
      justify-content: space-between;
      background-color: $gray-600;
      color: $white;
      border-bottom: 1px solid $gray-400;
      &__caret {
        @include media-breakpoint-up(md) {
          display: none;
        }
        margin-top: 2px;
      }
    }
    &__container {
      @include media-breakpoint-down(md) {
        display: none;
        &--open {
          display: block;
        }
      }
    }
    &__all {
      padding: 0px 10px;
      height: 50px;
      display: flex;
      align-items: center;
      background-color: $gray-500;
      text-transform: uppercase;
      border-bottom: 1px solid $gray-400;
    }
    &__tag {
      padding: 0px 10px;
      height: 50px;
      display: flex;
      align-items: center;
      background-color: $gray-300;
      text-transform: uppercase;
      border-bottom: 1px solid $gray-400;
    }
  }
</style>