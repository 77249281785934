// Libs
import FloatingVue from "floating-vue";
import tinymce from "tinymce";
import { createApp } from "vue";

// Bootstrap
import { localeService, modalService } from "./bootstrap";

// Router
import { router } from "./router";

// TinyMCE
(window as any).tinymce = tinymce;

const app = createApp({ template: "<router-view />" });

app.use(router);
app.use(localeService.init());
app.use(FloatingVue);
app.mount("#app");


modalService.attachUseCallback(app => {
  app.use(router);
  app.use(localeService.init());
});