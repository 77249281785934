<template>
  <router-link
    :is="disabled ? 'span' : 'router-link'"
    v-if="route"
    :to="route"
    :class="[
      'button',
      `button--${buttonStyle}`,
      `button--${buttonType}`,
      `button--${size}`,
      { 'button--disabled': disabled },
    ]"
  >
    <template v-if="loading">
      <i class="fas fa-spinner fa-pulse" />
    </template>
    <slot v-else />
  </router-link>
  <button
    v-else
    :disabled="disabled"
    :type="type"
    :class="[
      'button',
      `button--${buttonStyle}`,
      `button--${buttonType}`,
      `button--${size}`,
      { 'button--disabled': disabled },
    ]"
  >
    <template v-if="loading">
      <i class="fas fa-spinner fa-pulse" />
    </template>
    <slot v-else />
  </button>
</template>
<script lang="ts" setup>
import { RouteLocationRaw } from "vue-router";

withDefaults(
  defineProps<{
    type?: "button" | "submit";
    buttonStyle?: "primary" | "secondary" | "danger" | "success";
    buttonType?: "text" | "contained" | "outlined";
    size?: "sm" | "md" | "lg";
    disabled?: boolean;
    loading?: boolean;
    route?: RouteLocationRaw;
  }>(),
  {
    type: "button",
    buttonStyle: "primary",
    buttonType: "contained",
    size: "md",
    disabled: false,
    loading: false,
  }
);
</script>
<style lang="scss" scoped>
@import "../../scss/base";

.tooltip {
  display: inline-block;
}
.button {
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  background-color: transparent;
  border: 0px;
  &--text#{&}--primary {
    color: $primary;
  }
  &--text#{&}--secondary {
    color: $secondary;
  }
  &--text#{&}--danger {
    color: $danger;
  }
  &--text#{&}--success {
    color: $success;
  }
  &--contained, &--outlined {
    box-shadow: 0px 2px 5px 0px #777;
  }
  &--outlined#{&}--primary {
    color: $primary;
  }
  &--outlined#{&}--secondary {
    color: $secondary;
  }
  &--outlined#{&}--danger {
    color: $danger;
  }
  &--outlined#{&}--success {
    color: $success;
  }
  &--contained#{&}--primary {
    background-color: $primary;
    color: $white;
  }
  &--contained#{&}--secondary {
    background-color: $secondary;
    color: $white;
  }
  &--contained#{&}--danger {
    background-color: $danger;
    color: $white;
  }
  &--contained#{&}--success {
    background-color: $success;
    color: $white;
  }
  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  $padding-x: 1rem;
  $padding-y: 0.5rem;
  &--sm {
    padding: $padding-y * 0.8 $padding-x * 0.8;
    font-size: 0.8rem;
  }
  &--md {
    padding: $padding-y $padding-x;
    font-size: 1rem;
  }
  &--lg {
    padding: $padding-y * 1.6 $padding-x * 1.6;
    font-size: 1.5rem;
  }
}
</style>
