// DTO's
import { IImageCreateDTO, IImageDTO } from "../../../shared/dto/image.dto";

// Services
import { APIService } from "./api.service";

export class ImageService {
  public constructor(
    private readonly apiService: APIService,
  ) { }

  public async all(): Promise<IImageDTO[]> {
    return (await this.apiService.get<IImageDTO[]>("/image")).body;
  }

  public async get(id: number): Promise<IImageDTO> {
    return (await this.apiService.get<IImageDTO>(`/image/${id}`)).body;
  }

  public async create(image: IImageCreateDTO, file: File): Promise<void> {
    const formData = new FormData();
    formData.append("name", image.name);
    formData.append("tags", image.tags.join(","));
    formData.append("file", file);
    await this.apiService.post("/image", formData);
  }

  public async update(id: number, image: IImageCreateDTO, file?: File): Promise<void> {
    const formData = new FormData();
    formData.append("name", image.name);
    formData.append("tags", image.tags.join(","));
    if(file) {
      formData.append("file", file);
    }
    await this.apiService.put(`/image/${id}`, formData);
  }

  public async delete(id: number): Promise<void> {
    await this.apiService.delete(`/image/${id}`);
  }
}