import { ComponentCustomProperties, App } from "vue";
import { componentType, componentProps } from "../modules/modal.module";
import { promiseComponent } from "../modules/promise-component.module";
export type globalProps = ComponentCustomProperties & Record<string, any>;

export class ModalService {
  private useCallback?: (app: App) => void;

  public asPromise<C extends componentType, T = unknown>(component: C, props?: componentProps<C>): Promise<T> {
    return promiseComponent<T>(component, { props, callback: this.useCallback });
  }

  public attachUseCallback(useCallback: (app: App) => void): void {
    this.useCallback = useCallback;
  }
}