<template>
  <div
    class="tags"
    :class="{ 'tags--active': active }"
  >
    <label
      class="tags__label"
      :class="{ 'tags__label--active': active || input !== '' || modelValue.length > 0}"
      @click="focusInput"
    >{{ label }}</label>

    <div
      v-for="(value, index) in valueList"
      :key="`value_${index}`"
      class="tags__value"
      :class="{'tags__value--selected': selectedValueIndex === index}"
    >
      {{ value.text }}
      <i
        class="fas fa-times tags__times"
        @mousedown.prevent
        @click="removeValue(index)"
      />
    </div>
    <input
      ref="inputElement"
      v-model="input"
      class="tags__input"
      type="text"
      :disabled="disabled || maxReached"
      @focus="active = true"
      @blur="blur"
      @keydown="keyDown"
      @keypress="shouldContinue"
    >
    <ul
      v-if="!maxReached"
      class="tags__options"
      :class="{'tags__options--open': active}"
    >
      <li
        v-for="(value, index) in filteredOptions"
        :key="`option_${index}`"
        class="tags__option"
        :class="{'tags__option--selected': selectedOptionIndex === index}"
        @mousedown.prevent
        @click="addValue(index)"
      >
        {{ value.text }}
      </li>
    </ul>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref, watch } from "vue";

type option = { value: string, text: string };
export type options = option[];

const $props = withDefaults(defineProps<{
  label: string;
  disabled: boolean;
  modelValue: options;
  options: options;
  allowAdd: boolean;
  max: number;
}>(), {
  disabled: false,
  modelValue: [],
  options: [],
  allowAdd: false,
  max: -1,
});
const $emit = defineEmits([
  "update:modelValue",
]);

const inputElement = ref<HTMLInputElement | null>(null);
const input = ref("");
const active = ref(false);
const selectedOptionIndex = ref(-1);
const selectedValueIndex = ref(-1);

watch(input, (value: string, oldValue: string) => {
  if (value !== oldValue) {
    selectedValueIndex.value = -1;
    selectedOptionIndex.value = value !== "" ? 0 : -1;
  }
});
watch(() => $props.options, () => {
  if($props.allowAdd === false) {
    $emit("update:modelValue", $props.modelValue.filter((value: option) => $props.options.includes(value) === true));
  } 
});

const valueList = computed(() => $props.modelValue.map(
  (value: option) =>
    $props.options.find(option => option.value === value.value) ||
      value,
));

const filteredOptions = computed(() => $props.options.filter(
  option =>
    option.text.match(new RegExp(`${input.value}`, "i")) &&
    $props.modelValue.map(option => option.value).indexOf(option.value) === -1,
));

const selectedValue = computed(() => valueList.value[selectedValueIndex.value]);
const selectedOption = computed(() => filteredOptions.value[selectedOptionIndex.value]);
const maxReached = computed(() => $props.max !== -1 && $props.max <= $props.modelValue.length);

function focusInput(): void {
  inputElement.value?.focus();
}
function blur(): void {
  active.value = false;
  selectedOptionIndex.value = -1;
  addToValues();
}
function selectNextOption(): void {
  if (selectedOptionIndex.value + 1 < filteredOptions.value.length) {
    selectedOptionIndex.value++;
  } else {
    selectedOptionIndex.value = 0;
  }
}
function selectPreviousOption(): void {
  if (selectedOptionIndex.value - 1 >= 0) {
    selectedOptionIndex.value--;
  } else {
    selectedOptionIndex.value = filteredOptions.value.length - 1;
  }
}
function selectNextValue(): void {
  if (selectedValueIndex.value === -1) {
    return;
  }
  if (selectedValueIndex.value + 1 < valueList.value.length) {
    selectedValueIndex.value++;
  } else {
    selectedValueIndex.value = -1;
  }
}
function selectPreviousValue(): void {
  if (selectedValueIndex.value === -1) {
    selectedValueIndex.value = valueList.value.length - 1;
  } else if (selectedValueIndex.value - 1 >= 0) {
    selectedValueIndex.value--;
  }
}
function addToValues(): boolean {
  if (maxReached.value) {
    return false;
  }
  let returnValue = false;
  if (
    selectedOption.value !== undefined &&
    $props.modelValue.map(option => option.value).indexOf(selectedOption.value.value) === -1
  ) {
    $emit("update:modelValue", [...$props.modelValue, selectedOption.value]);
    returnValue = true;
  } else if (
    input.value !== "" &&
    $props.allowAdd &&
    $props.modelValue.map(option => option.text).indexOf(input.value) === -1
  ) {
    $emit("update:modelValue", [...$props.modelValue, { value: input.value, text: input.value }]);
    returnValue = true;
  }

  input.value = "";
  selectedValueIndex.value = -1;
  selectedOptionIndex.value = -1;

  return returnValue;
}

function removeFromValues(): void {
  if (input.value !== "") {
    return;
  }
  if (selectedValue.value !== undefined) {
    $emit("update:modelValue", $props.modelValue.filter(value => value.value !== selectedValue.value.value));
    selectedValueIndex.value = -1;
  } else {
    selectedValueIndex.value = $props.modelValue.length - 1;
  }
}

function removeValue(index: number): void {
  const temp = [...$props.modelValue];
  temp.splice(index, 1);
  $emit("update:modelValue", temp);
}

function addValue(index: number): void {
  $emit("update:modelValue", [...$props.modelValue, filteredOptions.value[index]]);
  input.value = "";
}

function keyDown(event: KeyboardEvent): void {
  switch (event.key) {
    case "ArrowUp":
      selectPreviousOption();
      break;
    case "ArrowDown":
      selectNextOption();
      break;
    case "ArrowLeft":
      selectPreviousValue();
      break;
    case "ArrowRight":
      selectNextValue();
      break;
    case "Enter":
      if (addToValues()) {
        event.preventDefault();
      }
      break;
    case "Backspace":
      removeFromValues();
  }
}

function shouldContinue(event: KeyboardEvent): void {
  if (maxReached.value) {
    event.preventDefault();
  }
}

</script>
<style lang="scss" scoped>
@import "../../scss/base";

.tags {
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  min-height: 3rem;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  border-bottom: 1px solid $gray-500;
  &__label {
    position: absolute;
    font-size: 1rem;
    cursor: text;
    transition: transform 0.2s ease-out, color 0.2s ease-out,
      -webkit-transform 0.2s ease-out;
    transform-origin: 0% 100%;
    text-align: initial;
    transform: translateY(12px);
    color: $gray-500;
    &--active {
      color: $primary;
      transform: translateY(-14px) scale(0.8);
      transform-origin: 0 0;
    }
    &--gray {
      color: $gray-200;
    }
  }
  &--active {
    border-bottom: 1px solid $primary;
    box-shadow: 0 1px 0 0 $primary;
  }
  &__times {
    position: relative;
    top: 1px;
    cursor: pointer;
  }
  &__input {
    font-size: 16px;
    flex-grow: 2;
    border: 0px;
    width: 10px;
    height: 32px;
    margin-top: 10px;
    box-shadow: none;
    box-sizing: content-box;
    align-self: flex-end;
    &:focus {
      border: 0px;
      outline: none;
    }
    &:disabled {
      background-color: transparent;
    }
  }
  &__value {
    height: 32px;
    font-size: 13px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.6);
    line-height: 32px;
    padding: 0 12px;
    border-radius: 16px;
    background-color: $gray-200;
    margin-right: 5px;
    margin-top: 0.5rem;
    align-self: center;
    &--selected {
      background-color: $gray-500;
    }
  }
  &__options {
    width: 100%;
    margin: 0px;
    padding: 0px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    background-color: $white;
    list-style-type: none;
    position: absolute;
    top: 50px;
    z-index: 10000;
    display: none;
    &--open {
      display: block;
    }
  }
  &__option {
    padding: 0px 15px;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
    &--selected,
    &:hover {
      background-color: $gray-300;
    }
  }
}
</style>