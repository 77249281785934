// Libs
import { AxiosInstance } from "axios";

// Shared services  
import { APIService as BaseAPIService } from "../../../shared/services/api.service";
import { BroadcastService } from "../../../shared/services/broadcast.service";

// Services
import { language, LocaleService } from "./locale.service";

export class APIService extends BaseAPIService {
  public constructor(
    baseUrl: string,
    axios: AxiosInstance,
    broadcastService: BroadcastService,
    localeService: LocaleService,
  ) {
    super(baseUrl, axios, broadcastService);

    // Subscribe to locale change to update default language header
    localeService.onChange((to: language) => this.setLanguageHeader(to.key));
  }
  
  private setLanguageHeader(languageKey: string): void {
    this.setDefaultHeaders({"Accept-Language": languageKey });
  }
}