import { DefineComponent, createApp, App } from "vue";

type componentType = DefineComponent<any, any, any, any, any>;
type componentProps<C extends componentType> = InstanceType<C>["$props"];

export async function promiseComponent<T, C extends componentType = componentType>(component: C, { props, callback }: { props?: componentProps<C>, callback?: (app: App) => void } = {}): Promise<T> {
  return new Promise<T>(resolve => {
    // Create app
    const app = createApp(component, { ...props, end: (data: T) => {
      app.unmount();
      document.body.removeChild(wrapper);
      resolve(data);
    } });
    
    if(callback !== undefined) {
      callback(app);
    }
    
    // Create wrapper element and attach it to the body
    const wrapper = document.createElement("div");
    document.body.appendChild(wrapper);
    
    // Mount app to wrapper
    app.mount(wrapper);
  });
}