import { LocationQueryValue } from "vue-router";

export function getRouteParam(param: LocationQueryValue | LocationQueryValue[] | undefined): string | undefined {
  if(param instanceof Array) {
    return getRouteParam(param[0]);
  } else if(param) {
    return param;
  }
}

export function routerParamToNumber(param: string | string[] | undefined): number | undefined {
  const value = getRouteParam(param);
  return value ? parseInt(value) : undefined;
}

export function thousandSeparator(value: number): string {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}
export function currencyFormat(value: number): string {
  return `${thousandSeparator(value)} kr`;
}