<template>
  <image-form-component
    v-if="initWithImage"
    type="edit"
    :init-with="initWithImage"
    @submit="save"
    @close="close"
  />
</template>
<script lang="ts" setup>
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { imageService, API_BASE_URL } from "../bootstrap";
import { routerParamToNumber } from "../utils";
import ImageFormComponent, { image, imageInitWith } from "./image-form.component.vue";

const $router = useRouter();
const $route = useRoute();

const id = computed<number | undefined>(() => {
  return routerParamToNumber($route.params.id);
});

const initWithImage = ref<imageInitWith | null>(null);

onMounted(async () => {
  if(!id.value) {
    return;
  }
  const image = await imageService.get(id.value);
  initWithImage.value = {
    name: image.name,
    tags: image.tags,
    image: removeFolderFromPath(image.path),
    imageUrl: `${API_BASE_URL}${image.path}`
  };
});

async function save(form: image): Promise<void> {
  if(!id.value) {
    return;
  }
  try {
    await imageService.update(id.value, {
      name: form.name,
      tags: form.tags
    }, form.image);

    close();
  } catch {
    alert("Something went wrong");
  }
}

function close(): void {
  $router.push({ path: "/image", query: { ...$route.query } });
}

function removeFolderFromPath(path: string): string {
  return path.split("/").pop() || "";
}
</script>
<style lang="scss" scoped>

</style>