
<template>
  <div
    class="file-input"
    @click="triggerInput"
  >
    <input
      ref="input"
      class="file-input__input"
      type="file"
      :multiple="multiple"
    >
    <div
      class="file-input__placeholder"
    >
      <div
        class="file-input__placeholder__cover"
      />
      <input-component
        :label="label"
        :model-value="fileNames || initValue"
        :disabled="true"
      />
    </div>
    <button-component>
      <i class="fa-solid fa-upload" />
    </button-component>
  </div>
</template>
<script lang="ts" setup>
import { onMounted, ref, onUnmounted, computed, watch } from "vue";

import ButtonComponent from "./button.component.vue";
import InputComponent from "./input.component.vue";

const $props = withDefaults(defineProps<{
  modelValue?: FileList;
  initValue?: string;
  multiple?: boolean;
  label?: string;
}>(), {
  modelValue: undefined,
  initValue: undefined,
  multiple: false,
  label: ""
});

const $emit = defineEmits([
  "update:modelValue"
]);

watch(() => $props.modelValue, (value?: FileList) => {
  if(value) {
    fileList.value = value;
  }
});

const input = ref<HTMLInputElement | null>(null);
const fileList = ref<FileList | null>(null);

const fileNames = computed<string | undefined>(() => {
  if(fileList.value) {
    return Array.from(fileList.value).map((file) => file.name).join(", ");
  }

  return undefined;
});


const fileListListner = (event: Event): void => {
  if(event.target instanceof HTMLInputElement && event.target.files) {
    $emit("update:modelValue",  event.target.files);    
  }
};

onMounted(() => {
  input.value?.addEventListener("change", fileListListner);
});

onUnmounted(() => {
  input.value?.removeEventListener("change", fileListListner);
});
function triggerInput(): void {
  input.value?.click();
}
</script>
<style lang="scss" scoped>
  .file-input {
    display: flex;
    align-items: end;
    cursor: pointer;
    &__input {
      margin: 0px;
      padding: 0px;
      height: 0px;
      width: 0px;
      display: none;
    }
    &__placeholder {
      position: relative;
      flex: 1;
      margin-right: 10px;
      &__cover {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0%;
      }
    }
  }
</style>