export type observer<T> = (payload?: T) => void;

export interface ISubscription {
  unsubscribe: () => void;
}

export class BroadcastService {
  private observers: { [key: string]: (observer<never>)[] } = {};

  /**
   * Subscribe to event handler
   * @param name Name of event
   * @param callback The callback to be called each time event emits
   */
  public subscribe<T = unknown>(name: string, callback: observer<T>): ISubscription;
  public subscribe<T = unknown>(names: string[], callback: observer<T>): ISubscription[];
  public subscribe<T = unknown>(name: string | string[], callback: observer<T>): ISubscription[];
  public subscribe<T = unknown>(name: string | string[], callback: observer<T>): ISubscription | ISubscription[] {
    if (name instanceof Array) {
      return name.map((name: string) => this.subscribe(name, callback));
    }

    // Looks for named observer list. If not create it
    if (!(name in this.observers)) {
      this.observers[name] = [];
    }

    // Pushes callback to observer list
    this.observers[name].push(callback);

    return {
      // Return unsubscribe method
      unsubscribe: (): void => {
        // Find callback in observer list
        const index = this.observers[name].findIndex((observer: observer<never>) => observer === callback);
        // Removes it if found
        if (index !== -1) { this.observers[name].splice(index, 1); }
      }
    };
  }

  public on<T = unknown>(name: string, callback: observer<T>): void;
  public on<T = unknown>(names: string[], callback: observer<T>): void;
  public on<T = unknown>(name: string | string[], callback: observer<T>): void;
  public on<T = unknown>(name: string | string[], callback: observer<T>): void {
    if (name instanceof Array) {
      const subscriptions = name.map((name: string) => this.subscribe(name, (payload?: T) => {
        callback(payload);
        this.unsubscribeToSubscriptions(subscriptions);
      }));

      return;
    }
    const subscription = this.subscribe(name, (payload?: T) => {
      callback(payload);
      subscription.unsubscribe();
    });
  }

  /**
   * Emit event
   * @param name Name of event
   * @param payload Payload to send
   */
  public emit<T>(name: string, payload?: T): void {
    // Look if observer with name exists
    if (name in this.observers) {
      // Call each observer callback in observer list with payload
      this.observers[name].forEach((observer: observer<never>) => observer(payload as never));
    }
  }

  public unsubscribeAll(): void;
  public unsubscribeAll(name: string): void;
  public unsubscribeAll(name?: string): void {
    if (name !== undefined) {
      this.observers[name] = [];
      return;
    }

    Object.keys(this.observers).forEach((name: string) => this.observers[name] = []);
  }

  public createInstance(): BroadcastService {
    return new BroadcastService();
  }

  private unsubscribeToSubscriptions(subscriptions: ISubscription[]): void {
    subscriptions.forEach((subscription: ISubscription) => subscription.unsubscribe());
  }
}
