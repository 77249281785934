<template>
  <layout-component>
    <div class="login">
      <form @submit.prevent="login">
        <input-component
          v-model="form.email"
          :label="$t('login.email')"
          input-mode="email"
        />

        <input-component
          v-model="form.password"
          type="password"
          :label="$t('login.password')"
        />
        <button-component
          type="submit"
          class="login__submit-button"
        >
          {{ $t("login.submit") }}
        </button-component>
      </form>
    </div>
  </layout-component>
</template>
<script lang="ts" setup>
// Libs
import { ref } from "vue";
import { useRouter } from "vue-router";

import { authService } from "../bootstrap";
import ButtonComponent from "./button.component.vue";
import InputComponent from "./input.component.vue";
import LayoutComponent from "./layout.component.vue";

const $router = useRouter();

const form = ref({
  email:
    !(window as any).Cypress && process.env.ENV === "local"
      ? "test@test.test"
      : "",
  password:
    !(window as any).Cypress && process.env.ENV === "local" ? "test" : "",
});

async function login(): void {
  try {
    await authService.login(form.value.email, form.value.password);
    $router.push("/image");
  } catch {
    alert("Wrong email and/or password!");
  }
}
</script>
<style lang="scss" scoped>
  .login {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    &__submit-button {
      margin-top: 20px;
    }
  }
</style>