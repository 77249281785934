{
  "name": "Bildstöd",
  "language": {
    "en": "Engelska",
    "sv": "Svenska"
  },
  "menu": {
    "image": "Bilder",
    "login": "Logga in",
    "logout": "Logga ut"
  },
  "login": {
    "email": "E-post",
    "password": "Lösenord",
    "submit": "Logga in"
  },
  "image": {
    "image": "Bild",
    "name": "Namn",
    "tags": "Taggar"
  },
  "all": "Alla",
  "print": "Skriv ut",
  "action": {
    "action": "Action | Actions",
    "create": "Skapa {entity}",
    "add": "Lägg till {entity}",
    "register": "Registrera {entity}",
    "edit": "Rediger {entity}",
    "edit_with_name": "Redigera {entity} \"{name}\"",
    "delete": "Ta bort {entity}",
    "delete_with_name": "Ta bort {entity} \"{name}\"",
    "delete_confirm": "Är du säker på att du vill ta bort {entity}?",
    "delete_confirm_with_name": "Är du säker på att du vill ta bort {entity} \"{name}\"?"
  },
  "modal": {
    "alert": "Varning!",
    "save": "Spara",
    "cancel": "Avbryt",
    "ok": "OK",
    "confirm": "Bekräfta",
    "yes": "Ja",
    "no": "Nej",
    "abort": "Avbryt"
  },
  "createdby": "Skapad av {name} den {date}",
}