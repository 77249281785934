// Libs
import Axios from "axios";
import { detect } from "detect-browser";

// Services
import { BroadcastService } from "../../shared/services/broadcast.service";
import { APIService } from "./services/api.service";
import { AuthService } from "./services/auth.service";
import { BrowserService } from "./services/browser.service";
import { ImageService } from "./services/image.service";
import { LocaleService } from "./services/locale.service";
import { ModalService } from "./services/modal.service";
import { StorageService } from "./services/storage.service";
import { UserService } from "./services/user.service";

export const API_BASE_URL = process.env.API_BASE_URL || "http://api.test.test/4567";

// Create services
export const modalService = new ModalService();
export const browserService = new BrowserService(window, detect);
export const storageService = new StorageService("storage", new BroadcastService());
export const localeService = new LocaleService("en", {
  sv: "swedish",
  en: "english",
}, new BroadcastService(), storageService, browserService);
const apiService = new APIService(API_BASE_URL, Axios.create(), new BroadcastService(), localeService);
export const authService = new AuthService(apiService, storageService, new BroadcastService());
export const userService = new UserService(apiService, storageService, new BroadcastService(), authService);
export const imageService = new ImageService(apiService);
