import { IUserDTO } from "../../../shared/dto/user.dto";
import { BroadcastService, ISubscription } from "../../../shared/services/broadcast.service";
import { APIService } from "./api.service";
import { AuthService } from "./auth.service";
import { StorageService } from "./storage.service";


export class UserService {
  constructor(
    private readonly apiService: APIService,
    private readonly storageService: StorageService,
    private readonly broadcastService: BroadcastService,
    private readonly authService: AuthService,
  ) {
    this.authService.onLoginOrLogout(async () => {
      if (this.authService.isAuth) {
        this.setMe(await this.getMe());
      } else {
        this.deleteMe();
      }
    });
    this.init();
  }

  private async init(): Promise<void> {
    if (this.authService.isAuth && this.me === undefined) {
      this.setMe(await this.getMe());
    }
  }

  public get me(): IUserDTO | undefined {
    return this.storageService.get<IUserDTO>("me");
  }

  public onMeChange(callback: () => void): ISubscription {
    return this.broadcastService.subscribe("user.me.change", callback);
  }

  private setMe(user: IUserDTO): void {
    this.storageService.set("me", user);
    this.broadcastService.emit("user.me.change", user);
  }
  private deleteMe(): void {
    this.storageService.delete("me");
    this.broadcastService.emit("user.me.change");
  }

  private async getMe(): Promise<IUserDTO> {
    return (await this.apiService.get<IUserDTO>("/user/me")).body;
  }
}