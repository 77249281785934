<template>
  <label
    class="checkbox"
    :for="computedId"
  >
    <input
      :id="computedId"
      ref="checkbox"
      class="checkbox__input"
      type="checkbox"
      :disabled="disabled"
      @input="onChange"
    >
    <label
      class="checkbox__label"
      :for="computedId"
    >
      <template v-if="label != ''">{{ label }}</template>
      <slot v-else />
    </label>
  </label>
</template>
<script lang="ts" setup>
// Libs
import {
  watch,
  ref,
  computed,
  onMounted,
} from "vue";

const props = withDefaults(
  defineProps<{
    modelValue?: boolean;
    label?: string;
    indeterminate?: boolean;
    disabled?: boolean;
    id?: string;
  }>(),
  {
    modelValue: false,
    label: "",
    indeterminate: false,
    disabled: false,
  }
);

const $emit = defineEmits(["update:modelValue"]);

const checkbox = ref<HTMLInputElement | null>(null);

watch(
  () => props.modelValue,
  (value: boolean) => {
    if (checkbox.value !== null) {
      checkbox.value.checked = value;
    }
  }
);

watch(
  () => props.indeterminate,
  () => {
    if (checkbox.value !== null) {
      checkbox.value.indeterminate = props.indeterminate;
    }
  }
);

let calculatedId = ref<string | null>(null);

const computedId = computed(() => {
  if (props.id === undefined) {
    if (calculatedId.value === null) {
      calculatedId.value = `checkbox_${Math.round(
        Math.random() * 10000000000
      )}`;
    }
    return calculatedId.value;
  }
  return props.id;
});

onMounted(() => {
  if (checkbox.value !== null) {
    checkbox.value.checked = props.modelValue;
    checkbox.value.indeterminate = props.indeterminate;
  }
});

function onChange(): void {
  if (checkbox.value !== null) {
    $emit("update:modelValue", checkbox.value.checked);
  }
}
</script>
<style lang="scss" scoped>
.checkbox {
  display: flex;
  cursor: pointer;
  &__input {
    width: 22px;
    height: 22px;
    border: 1px solid #aaa;
    border-radius: 1px;
    cursor: pointer;
    text-align: center;
    appearance: none;
    &::before {
      font-size: 16px;
      line-height: 22px;
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      color: #000;
      position: relative;
      top: -1px;
    }
    &:checked::before {
      font: var(--fa-font-solid);
      content: "\f00c";
    }
    &:indeterminate::before {
      font: var(--fa-font-solid);
      content: "\f068";
    }
  }
  &__label {
    margin-left: 5px;
    flex: 1;
    align-self: stretch;
    display: flex;
    align-items: center;
  }
}
</style>
