
<template>
  <div class="modal">
    <div class="modal__container">
      <component
        :is="isForm ? 'form' : 'div'"
        class="modal__layout"
        @submit.prevent="submit"
      >
        <div
          v-if="hasHeader()"
          class="modal__layout__header"
        >
          <h1 class="modal__layout__header__text">
            <template v-if="preLoading">
              <i class="fa-solid fa-spinner fa-spin-pulse" />
            </template>
            <template v-else>
              <template v-if="header">
                {{ header }}
              </template>
              <slot
                v-else
                name="header"
              />
            </template>
          </h1>
          <button-component
            v-if="showCloseButton"
            class="modal__layout__header__close-button"
            button-type="contained"
            button-size="sm"
            :disabled="isLoading"
            @click="abort"
          >
            <i class="fa-solid fa-xmark" />
          </button-component>
        </div>
        <div class="modal__layout__content">
          <slot />
        </div>
        <div
          v-if="hasFooter()"
          class="modal__layout__footer"
        >
          <template v-if="footer">
            <template v-if="!isCustomFooter">
              <div
                class="modal__layout__footer__buttons"
              >
                <button-component
                  :type="isForm ? 'submit' : 'button'"
                  button-style="primary"
                  :loading="loading"
                  :disabled="!valid || isLoading"
                  @click="!isForm ? submit : undefined"
                >
                  <template v-if="footer === 'confirm_cancel'">
                    {{ $t("modal.confirm") }}
                  </template>
                  <template v-else-if="footer === 'yes_no'">
                    {{ $t("modal.yes") }}
                  </template>
                  <template v-else-if="footer === 'save_abort'">
                    {{ $t("modal.save") }}
                  </template>
                </button-component>
                <button-component
                  button-style="secondary"
                  :disabled="isLoading"
                  @click="abort"
                >
                  <template v-if="footer === 'confirm_cancel'">
                    {{ $t("modal.cancel") }}
                  </template>
                  <template v-else-if="footer === 'yes_no'">
                    {{ $t("modal.no") }}
                  </template>
                  <template v-else-if="footer === 'save_abort'">
                    {{ $t("modal.abort") }}
                  </template>
                </button-component>
              </div>
            </template>
            <template v-else>
              {{ footer }}
            </template>
          </template>
          <slot
            v-else
            name="footer"
          />
        </div>
      </component>
    </div>
  </div>
</template>
<script lang="ts" setup>
// Libs
import { onUnmounted, onMounted, computed, useSlots } from "vue";

// Components
import ButtonComponent from "./button.component.vue";

const $slots = useSlots();
const $props = withDefaults(defineProps<{
  hideOnEsc?: boolean;
  showCloseButton?: boolean;
  header?: string;
  footer?: "confirm_cancel" | "yes_no" | "save_abort" | string;
  valid?: boolean;
  loading?: boolean;
  preLoading?: boolean;
}>(), {
  hideOnEsc: true,
  showCloseButton: true,
  valid: true,
  loading: false,
  preLoading: false
});
const $emit = defineEmits(["close", "submit"]);

const escEvent = (event: KeyboardEvent): void => {
  if(event.code === "Escape") {
    if($props.hideOnEsc) {
      abort();
    }
  }
};

function hasHeader(): boolean {
  return !!$props.header || !!$slots.header;
}
function hasFooter(): boolean {
  return !!$props.footer || !!$slots.footer;
}
const isCustomFooter = computed(() => {
  return $props.footer !== "confirm_cancel" && $props.footer !== "yes_no" && $props.footer !== "save_abort";
});
const isForm = computed(() => !isCustomFooter.value);
const isLoading = computed(() => $props.loading || $props.preLoading);

onMounted(() => {
  document.addEventListener("keydown", escEvent);
  document.body.style.overflow = "hidden";
});
onUnmounted(() => {
  document.body.style.overflow = "auto";
  document.removeEventListener("keydown", escEvent);
});

function submit(): void {
  $emit("submit");
}
function abort(): void {
  $emit("close");
}

</script>
<style lang="scss" scoped>
@import "../../scss/base";

.modal {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  &__container {
    @include container(true);
    min-height: 50vh;
    background-color: #fff;
    border: 2px solid #444;
    border-radius: 2px;
    box-shadow: 0px 5px 10px 2px #444;
    display: flex;
  }
  &__layout {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    &__header {
      padding: 20px;
      min-height: 75px;
      background-color: gray;
      display: flex;
      width: 100%;
      justify-content: space-between;
      &__text {
        align-items: center;
        display: flex;
        margin: 0px;
        font-size: 18px;
        color: #eee;
      }
      &__close-button {
      }
    }
    &__content {
      padding: 20px;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
    &__footer {
      border-top: 1px solid #aaa;
      background-color: #eee;
      padding: 20px;
      &__buttons {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
      }
    }
  }
}
</style>