<template>
  <nav class="menu">
    <ul class="menu__list">
      <li
        v-for="(item, index) of menu"
        :key="`item_${index}`"
        class="menu__list__item"
      >
        <router-link
          class="menu__list__item__link"
          :to="item.path"
          active-class="menu__list__item__link--active"
        >
          {{ item.title }}
        </router-link>
      </li>
    </ul>
    <ul class="menu__list">
      <li
        v-if="!isAuth"
        class="menu__list__item"
      >
        <router-link
          class="menu__list__item__link"
          to="/login"
        >
          {{ $t('menu.login') }}
        </router-link>
      </li>
      <template v-else>
        <li class="menu__list__item">
          <router-link
            class="menu__list__item__link"
            to="/logout"
          >
            {{ $t('menu.logout') }}
          </router-link>
        </li>
      </template>
    </ul>
  </nav>
</template>
<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { IUserDTO } from "../../../shared/dto/user.dto";
import { ISubscription } from "../../../shared/services/broadcast.service";
import { authService, userService } from "../bootstrap";

const { t: $t } = useI18n();

const isAuth = ref<boolean>(authService.isAuth);
const user = ref<IUserDTO | null>(userService.me || null);

let isAuthSubscription: ISubscription | undefined;
let userSubscription: ISubscription | undefined;
onMounted(() => {
  isAuthSubscription = authService.onLoginOrLogout(() => {
    isAuth.value = authService.isAuth;
  });
  userSubscription = userService.onMeChange(() => {
    user.value = userService.me || null;
  });
});
onUnmounted(() => {
  if (isAuthSubscription) {
    isAuthSubscription.unsubscribe();
  }
  if (userSubscription) {
    userSubscription.unsubscribe();
  }
});

const menu = computed<Omit<menuType, "auth">[]>(() => {
  let menu;
  if (!isAuth.value) {
    menu = _menu.filter((item) => item.auth !== true);
  } else {
    menu = _menu;
  }

  return menu.map((item) => ({
    title: item.title,
    path: item.path,
  }));
});

type menuType = {
  title: string;
  path: string;
  auth?: boolean;
};
const _menu: menuType[] = [
  { title: $t("menu.image"), path: "/image" },
];
</script>
<style lang="scss" scoped>
@import "../../scss/base";

.menu {
  display: flex;
  justify-content: space-between;
  .menu__list {
    display: flex;
    flex-direction: row;
    list-style: none;
    padding: 0;
    margin: 0;
    &__item {
      margin-right: 1rem;
      &__link {
        color: #ccc;
        text-decoration: none;
        text-transform: uppercase;
        &--active, &:hover {
          color: #fff;
        }
      }
    }
  }
}
</style>
