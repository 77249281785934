<template>
  <div
    :class="['image', { 'image--checked': image.checked, 'no-print': !image.checked, 'print-only': onlyPrint }]"
    @click="check(image)"
  >
    <router-link
      v-if="showEditButton && image.id"
      class="image__edit no-print"
      :to="{ path: `/image/${image.id}`, query: { ...$route.query } }"
    >
      <i class="fa-regular fa-pen-to-square" />
    </router-link>
    <span
      v-if="showDeleteButton"
      class="image__delete no-print"
      @click="$emit('delete', image.id)"
    >
      <i class="fa-regular fa-trash-can" />
    </span>
    <img
      class="image__image"
      :src="image.path"
    >
    <p
      :class="`image__title image__title--${fontSize(image.name)}`"
      :style="`font-size: ${fontSize(image.name)}`"
    >
      {{ image.name }}
    </p>
    <div
      v-if="image.checked"
      class="image__counter"
    >
      <button
        class="image__counter__decrease"
        @click="(event) => { event.stopPropagation(); image.counter > 1 ? image.counter-- : undefined }"
      >
        <i class="fa-solid fa-minus" />
      </button>
      <div class="image__counter__number">
        {{ image.counter }}
      </div>
      <button
        class="image__counter__increase"
        @click="(event) => { event.stopPropagation(); image.counter++; }"
      >
        <i class="fa-solid fa-plus" />
      </button>
    </div>
  </div>
</template>
<script lang="ts" setup>

export type image = {
  id?: number;
  name: string;
  path: string;
  checked?: boolean;
  counter: number;
}

const $props = withDefaults(defineProps<{
  image: image;
  showEditButton: boolean;
  showDeleteButton: boolean;
  onlyPrint: boolean
}>(), {
  showEditButton: true,
  showDeleteButton: false,
  onlyPrint: false
});

const $emit = defineEmits(["delete"]);

function check(image: image): void {
  image.checked = !image.checked;
  if(!image.checked) {
    image.counter = 1;
  }
}

function fontSize(text: string): string {
  if (text.length > 14) {
    return "long";
  }
  if (text.length > 10) {
    return "medium";
  }
  return "short";
}
</script>
<style lang="scss" scoped>
@import "../../scss/base";

.no-print {
  @media print {
    display: none !important;
  }
}
.image {
  font-size: 24px;
  @media print {
    padding: 2mm 0;
    width: 51mm;
    height: 46mm;
    border-width: 1pt;
    border-radius: 4.233mm;
  }
  @media screen {
    padding: 8px 0;
    width: 191px;
    height: 156px;
    border-width: 1px;
    border-radius: 4.233mm;
    
    @include media-breakpoint-down(sm) {
      font-size: 20px;
      width: 150px;
      height: 120px;
    }
  }
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  border-color: #0029E6;
  border-style: solid;
  cursor: pointer;
  position: relative;
  &--checked {
    @media screen {
      border-color: #f00;
      border-width: 4px;
      padding: 5px 0px;
    }
  }
  &__edit {
    position: absolute;
    top: 5px;
    left: 10px;
  }
  &--checked &__edit {
    top: 2px;
    left: 7px;
  }
  &__delete {
    position: absolute;
    top: 5px;
    right: 10px;
  }
  &--checked &__delete {
    top: 2px;
    right: 7px;
  }
  &__image {
    @media print {
      max-height: 70%;
      max-width: 70%;
    }
    @media screen {
      max-width: 140px;
      max-height: 90px;
      @include media-breakpoint-down(md) {
        max-width: 100px;
        max-height: 50px;
      }
    }
    margin-bottom: 10px;
  }
  &__title {
    padding: 0px;
    margin: 0px;
    font-family: arial;
    
    &--long {
      font-size: 0.6em;
    }
    &--medium {
      font-size: 0.8em;
    }
    &--short {
      font-size: 1em;
    }
  }
  &__counter {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__increase, &__decrease {
      border: none;
      background-color: transparent;
    }
    &__increase {

    }
    &__decrease {

    }
    &__number {
      font-size: 1.5em;
      color: #fbfb73;
      text-shadow: 0px 0px 5px #000;
    }

    @media print {
      display: none;
    }
  }
}
</style>