{
  "name": "Bildstöd",
  "language": {
    "en": "English",
    "sv": "Swedish"
  },
  "menu": {
    "start": "Start"
  },
  "action": {
    "action": "Action | Actions",
    "create": "Create {entity}",
    "add": "Add {entity}",
    "edit": "Edit {entity}",
    "edit_with_name": "Edit {entity} \"{name}\"",
    "delete": "Remove {entity}",
    "delete_with_name": "Remove {entity} \"{name}\"",
    "delete_confirm": "Are you sure you want to remove {entity} \"{name}\"?",
    "from": "@:from {entity}",
    "to": "@:to {entity}"
  },
  "from": "Från",
  "to": "till",
  "modal": {
    "alert": "Alert!",
    "save": "Save",
    "cancel": "Cancel",
    "ok": "OK",
    "confirm": "Are you sure?",
    "yes": "Yes",
    "no": "No"
  }
}