<template>
  <div>
    <router-view />
    <layout-component class="no-print">
      <div
        v-if="!loading"
        class="image-list"
      >
        <image-tag-list-component
          v-model="selectedTags"
          class="image-list__tags"
          :tags="tags"
        />
        <div class="image-list__container">
          <div class="image-list__buttons">
            <button-component
              v-if="isAuth"
              :route="{ path: '/image/add', query: { ... $route.query } }"
              class="image-list__add-button"
            >
              <i class="fa-solid fa-image" />
              {{ $t("action.add", { entity: $t('image.image').toLowerCase() }) }}
            </button-component>
            <button-component @click="print">
              <i class="fa-solid fa-print" />
              {{ $t("print") }}
            </button-component>
          </div>
          <div class="image-list__container__list">
            <template
              v-for="(image, index) of computedImageList"
              :key="`image_${index}`"
            >
              <image-component
                v-for="index2 in image.counter"
                :key="`image_${index}_${index2}`"
                :image="image"
                :show-edit-button="isAuth"
                :show-delete-button="isAuth"
                :delete-button="true"
                :only-print="index2 > 1"
                @delete="image.id ? deleteImage(image.id) : undefined"
              />
            </template>
          </div>
        </div>
      </div>  
    </layout-component>
  </div>
</template>
<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { IImageDTO } from "../../../shared/dto/image.dto";

import { ISubscription } from "../../../shared/services/broadcast.service";
import { imageService, API_BASE_URL, authService } from "../bootstrap";
import { getRouteParam } from "../utils";

import ButtonComponent from "./button.component.vue";
import ImageTagListComponent from "./image-tag-list.component.vue";
import ImageComponent, { image } from "./image.component.vue";
import LayoutComponent from "./layout.component.vue";

const $route = useRoute();
const $router = useRouter();
const $t = useI18n().t;

watch(() => $route.path, () => {
  //loadImages();
});

const tagsInRoute = computed<string[]>({
  get() {
    return getRouteParam($route.query.tags)?.split(",") || [];
  },
  set(tags: string[]) {
    const currentRouteQueriesWithoutTags = Object.fromEntries(Object.entries($route.query).filter(([key]) => key !== "tags"));
    $router.push({ path: $route.path, query: { ...currentRouteQueriesWithoutTags, ...(tags.length >= 1 ? { tags: tags.join(",") } : {}) }});
  }
});

const tags = computed(() => {
  const tags: string[] = [];

  images.value.forEach((image) => {
    image.tags.forEach((tag) => {
      if(!tags.includes(tag)) {
        tags.push(tag);
      }
    });
  });

  return tags;
});

const computedImageList = computed(() => {
  if(selectedTags.value.length === 0) {
    return imageList.value;
  }
  return imageList.value.filter(image => {
    return image.tags.some(tag => selectedTags.value.includes(tag));
  });
});

const loading = ref<boolean>(true);
const images = ref<IImageDTO[]>([]);
const imageList = ref<(image & { tags: string[] })[]>([]);
const selectedTags = ref<string[]>([]);
const isAuth = ref<boolean>(authService.isAuth);
watch(selectedTags, (tags: string[]) => {
  tagsInRoute.value = tags;
});

let isAuthSubscription: ISubscription | undefined = undefined;
onMounted(async () => {
  await loadImages();

  isAuthSubscription = authService.onLoginOrLogout(auth => {
    isAuth.value = auth;
  });
});

onUnmounted(() => {
  if(isAuthSubscription) {
    isAuthSubscription.unsubscribe();
  }
});

async function loadImages(): Promise<void> {
  loading.value = true;
  images.value = await imageService.all();
  imageList.value = images.value.map(image => ({
    id: image.id,
    name: image.name,
    path: `${API_BASE_URL}${image.path}`,
    tags: image.tags,
    checked: false,
    counter: 1,
  }));
  selectedTags.value = tagsInRoute.value;
  loading.value = false;
}

async function deleteImage(id: number): Promise<void> {
  const prompt = confirm($t("action.delete_confirm", { entity: $t("image.image").toLowerCase() }));
  if(!prompt) {
    return;
  }
  await imageService.delete(id);
  await loadImages();
}

function print(): void {
  window.print();
}

</script>
<style lang="scss" scoped>
  @import "../../scss/base";
  .image-list {
    @include media-breakpoint-up(md) {
      display: flex;
    }
    &__tags {
      @include media-breakpoint-down(md) {
        width: 100%;
      }
      width: 200px;
      margin-right: 20px;
    }
    &__buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 10px;
    }
    &__container {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: start;
      &__list {
        @include media-breakpoint-down(sm) {
          justify-content: center;
        }
        @media print {
          visibility: visible;
          position: absolute;
          left: 0;
          top: 0;
        }
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
      }
    }
  }
</style>