// Libs
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

// Bootstrap
import { authService } from "./bootstrap";

// Components
import ImageAddComponent from "./components/image-add.component.vue";
import ImageEditComponent from "./components/image-edit.component.vue";
import ImageListComponent from "./components/image-list.component.vue";
import LoginComponent from "./components/login.component.vue";
import RegisterComponent from "./components/register.component.vue";

// Middlewares
import * as middlewares from "./middlewares";

const routes: RouteRecordRaw[] = [
  { path: "/", redirect: "/image" },
  { path: "/image", component: ImageListComponent, children: [
    { path: "add", component: ImageAddComponent },
    { path: ":id", component: ImageEditComponent },
  ] },
  { path: "/login", component: LoginComponent },
  { path: "/register", component: RegisterComponent },
  { path: "/logout", component: { beforeRouteEnter: middlewares.logout(authService), mounted: () => router.replace("/image") } },
];

export const router = createRouter({
  history: createWebHistory(),
  routes,
});