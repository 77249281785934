<template>
  <div class="layout">
    <header class="layout__header">
      <div class="layout__container">
        <menu-component />
      </div>
    </header>
    <section class="layout__content">
      <div class="layout__container">
        <slot />
      </div>
    </section>
    <footer class="layout__footer">
      <div class="layout__container">
        © <a href="mailto:hannes@dwik.se">Hannes Dahlberg</a> - 2023
      </div>
    </footer>
  </div>
</template>
<script lang="ts" setup>
import { authService, localeService } from "../bootstrap";
import MenuComponent from "./menu.component.vue";
localeService.changeLanguage("sv");
const user = authService.user;
</script>
<style lang="scss" scoped>
@import "../../scss/base";

.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  &__header {
    padding: 1rem;
    background-color: gray;
    color: #fff;
  }
  &__content {
    flex: 1;
    padding: 1rem;
  }
  &__footer {
    padding: 1rem;
    background-color: gray;
    color: #fff;
  }
  &__container {
    @include container;
  }
}
</style>